import React, {useState} from 'react';
import {
	Box,
	Heading,
	Text,
	Table,
	Tbody,
	Tr,
	Td,
	Button,
	Label,
	useDisclosure,
} from '@tempo/core';
import {
	DeezerLogoIcon,
	MailIcon,
	CalendarIcon,
	ClockOutlinedIcon,
	PremiumOutlinedIcon,
	LockIcon,
	CopyIcon,
} from '@tempo/icons';
import UnlinkModal from '@components/UnlinkModal';
import ConfirmationModal from '@components/ConfirmationModal';
import ErrorModal from '@components/ErrorModal';
import ForceActivationModal from '@components/ForceActivationModal';

interface CustomerDetailsProps {
	data: {
		id: string;
		type: string;
		attributes: {
			_id: string;
			userId: number;
			legacyPartnerId: number;
			insertDate: string;
			updateDate: string;
			linked: boolean;
			linkDate: string;
			active: boolean;
			legacyOfferId: number;
			offerStartDate: string;
			offerEndDate: string;
		};
		relationships: {
			offer: {
				data: {
					type: string;
					id: string;
				};
			};
			user: {
				data: {
					type: string;
					id: string;
				}[];
			};
		};
	};
	included?: {
		id: string;
		type: string;
		attributes: {
			_id: string;
			legacyOfferId?: number;
			name?: string;
			partnerId?: number;
			username?: string;
			email?: string;
		};
	}[];
}

type CustomerDetailsCallback = {
	onCustomerUpdated: () => Promise<void>;
};

export interface LinkFormData {
	email?: string;
	subscriptionId?: string;
}

const TableHeading: React.FC<{children: React.ReactNode}> = ({children}) => (
	<Tr>
		<Td
			colSpan={2}
			display="flex"
			p="spacing.l"
			alignItems="center"
			gap="spacing.2xl"
			alignSelf="stretch"
			bg="background.neutral.secondary.default"
		>
			<Text
				size="sm"
				color="text.neutral.secondary.default"
				fontSize="var(--Accessibility-12, 12px)"
				fontStyle="normal"
				fontWeight={400}
				lineHeight="var(--Accessibility-16, 16px)"
			>
				{children}
			</Text>
		</Td>
	</Tr>
);

const TableRow: React.FC<{
	icon: typeof DeezerLogoIcon;
	label?: string;
	value?: string | number;
	buttons?: React.ReactElement<typeof Button>[];
	isLastRow?: boolean;
	isStatus?: boolean;
	isActivated?: boolean;
	dataTestId?: string;
}> = ({
	icon: Icon,
	label,
	value,
	buttons,
	isLastRow = false,
	isStatus = false,
	isActivated = false,
	dataTestId,
}) => (
	<Tr>
		<Td
			display="flex"
			p="spacing.l"
			alignItems="center"
			gap="spacing.l"
			alignSelf="stretch"
			borderBottom={isLastRow ? 'none' : '1px solid'}
			borderBottomColor={
				isLastRow ? 'transparent' : 'divider.neutral.primary.default'
			}
		>
			<Icon size="medium" />
			<Box>
				<Text
					alignSelf="stretch"
					color="text.neutral.secondary.default"
					fontSize="var(--Accessibility-12, 12px)"
					fontStyle="normal"
					fontWeight={400}
					lineHeight="var(--Accessibility-16, 16px)"
				>
					{label}
				</Text>
				{isStatus ? (
					<Label
						bg={
							isActivated
								? 'background.feedback.success.default'
								: 'background.feedback.success.disabled'
						}
						color={
							isActivated
								? 'text.neutral.inverse.default'
								: 'text.neutral.primary.default'
						}
						data-testid={dataTestId}
					>
						{isActivated ? 'Activated' : 'Not activated'}
					</Label>
				) : (
					<Text
						alignSelf="stretch"
						color="text.neutral.primary.default"
						fontSize="var(--Accessibility-16, 16px)"
						fontStyle="normal"
						fontWeight={400}
						lineHeight="var(--Accessibility-24, 24px)"
						data-testid={dataTestId}
					>
						{value}
					</Text>
				)}
				<Box
					display="flex"
					gap="8px"
					mt="4px"
					flexDirection={{base: 'column', sm: 'row'}}
				>
					{buttons &&
						buttons.map((button, index) => (
							<React.Fragment key={index}>{button}</React.Fragment>
						))}
				</Box>
			</Box>
		</Td>
	</Tr>
);

const CustomerDetails: React.FC<
	CustomerDetailsProps & CustomerDetailsCallback
> = ({data, included, onCustomerUpdated}) => {
	const getUser = () => {
		return included?.find((item) => item.type === 'User');
	};

	const getOffer = () => {
		return included?.find((item) => item.type === 'Offer');
	};

	const user = getUser();
	const offer = getOffer();

	const {
		isOpen: isUnlinkModalOpen,
		onOpen: onUnlinkModalOpen,
		onClose: onUnlinkModalClose,
	} = useDisclosure();
	const {
		isOpen: isUnlinkConfirmationModalOpen,
		onOpen: onUnlinkConfirmationModalOpen,
		onClose: onUnlinkConfirmationModalClose,
	} = useDisclosure();
	const {
		isOpen: isUnlinkErrorModalOpen,
		onOpen: onUnlinkErrorModalOpen,
		onClose: onUnlinkErrorModalClose,
	} = useDisclosure();
	const {
		isOpen: isForceActivationModalOpen,
		onOpen: onForceActivationModalOpen,
		onClose: onForceActivationModalClose,
	} = useDisclosure();
	const {
		isOpen: isForceActivationConfirmationModalOpen,
		onOpen: onForceActivationConfirmationModalOpen,
		onClose: onForceActivationConfirmationModalClose,
	} = useDisclosure();
	const {
		isOpen: isForceActivationErrorModalOpen,
		onOpen: onForceActivationErrorModalOpen,
		onClose: onForceActivationErrorModalClose,
	} = useDisclosure();
	const [isUnlinked, setIsUnlinked] = useState(false);
	const [linkFormData, setLinkFormData] = useState<LinkFormData>({
		email: '',
		subscriptionId: data.attributes._id,
	});

	const handleUnlink = async () => {
		onUnlinkModalClose();
		try {
			const response = await fetch(
				`/api/unlink?subscriptionId=${data.attributes._id}`,
			);

			if (!response.ok) {
				throw new Error('Unlink failed');
			}

			await response.json();
			setIsUnlinked(true);
			await onCustomerUpdated();
			onUnlinkConfirmationModalOpen();
		} catch (error) {
			onUnlinkErrorModalOpen();
		}
	};

	const handleForceActivation = async () => {
		onForceActivationModalClose();
		try {
			const response = await fetch(
				`/api/force-activation?subscriptionId=${data.attributes._id}&email=${linkFormData.email}`,
			);

			if (!response.ok) {
				throw new Error('Force activation failed');
			}

			await response.json();
			setIsUnlinked(false);
			await onCustomerUpdated();
			onForceActivationConfirmationModalOpen();
		} catch (error) {
			onForceActivationErrorModalOpen();
		}
	};

	const UnlinkConfirmationModal = () => (
		<ConfirmationModal
			isOpen={isUnlinkConfirmationModalOpen}
			onClose={onUnlinkConfirmationModalClose}
			title="Account unlinked"
			subtitle="The subscriber ID has been unlinked from the previous offer."
		/>
	);

	const ForceActivationConfirmationModal = () => (
		<ConfirmationModal
			isOpen={isForceActivationConfirmationModalOpen}
			onClose={onForceActivationConfirmationModalClose}
			title="Activation correctly done"
			subtitle="The subscriber ID has been linked to the offer."
		/>
	);

	const UnlinkErrorModal = () => (
		<ErrorModal
			isOpen={isUnlinkErrorModalOpen}
			onClose={onUnlinkErrorModalClose}
			onRetry={handleUnlink}
			title="The account has not been unlinked"
		/>
	);

	const ForceActivationErrorModal = () => (
		<ErrorModal
			isOpen={isForceActivationErrorModalOpen}
			onClose={onForceActivationErrorModalClose}
			onRetry={handleForceActivation}
			title="The activation failed"
		/>
	);

	return (
		<>
			<UnlinkModal
				isOpen={isUnlinkModalOpen}
				onClose={onUnlinkModalClose}
				onConfirm={handleUnlink}
			/>
			<UnlinkConfirmationModal />
			<UnlinkErrorModal />
			<ForceActivationModal
				isOpen={isForceActivationModalOpen}
				onClose={onForceActivationModalClose}
				onConfirm={handleForceActivation}
				linkFormData={linkFormData}
				setLinkFormData={setLinkFormData}
			/>
			<ForceActivationConfirmationModal />
			<ForceActivationErrorModal />
			<Box width="100%" pb="spacing.l">
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					mb="spacing.xl"
				>
					<Heading variant="display.s" data-testid="customer-name">
						{user?.attributes.username || 'User'}
					</Heading>
					{data.attributes.linked && !isUnlinked ? (
						<Button variant="outline" onClick={onUnlinkModalOpen}>
							Unlink
						</Button>
					) : (
						<Box>
							<Button
								variant="outline"
								mr="spacing.m"
								onClick={onForceActivationModalOpen}
							>
								Force activation
							</Button>
							<Button>Send activation link</Button>
						</Box>
					)}
				</Box>
				<Box display="flex" flexWrap="wrap">
					<Box
						width={['100%', '100%', '50%']}
						pr={[0, 0, 'spacing.m']}
						mb="spacing.xl"
					>
						<Table
							borderWidth="1px"
							borderStyle="solid"
							borderColor="divider.neutral.primary.default"
							data-testid="deezer-credentials-section"
						>
							<Tbody>
								<TableHeading>Deezer credentials</TableHeading>
								<TableRow
									icon={DeezerLogoIcon}
									label="Deezer pseudo"
									value={user?.attributes.username || '-'}
									dataTestId="deezer-sudo-name"
								/>
								<TableRow
									icon={MailIcon}
									label="Email address"
									value={user?.attributes.email || '-'}
									dataTestId="deezer-email-address"
								/>
								<TableRow
									isLastRow
									icon={LockIcon}
									label="Password"
									buttons={[
										<Button
											key="send"
											leftIcon={<MailIcon />}
											variant="outline"
											size="sm"
											data-testid="send-renewal-link-button"
										>
											Send renewal link
										</Button>,
										<Button
											key="copy"
											leftIcon={<CopyIcon />}
											variant="outline"
											size="sm"
											data-testid="copy-renewal-link-button"
										>
											Copy renewal link
										</Button>,
									]}
								/>
							</Tbody>
						</Table>
					</Box>

					<Box
						width={['100%', '100%', '50%']}
						pl={[0, 0, 'spacing.m']}
						mb="spacing.xl"
					>
						<Table
							borderWidth="1px"
							borderStyle="solid"
							borderColor="divider.neutral.primary.default"
							data-testid="subscriber-information-section"
						>
							<Tbody>
								<TableHeading>Subscriber info</TableHeading>
								<TableRow
									icon={DeezerLogoIcon}
									label="Subscriber ID"
									value={data.attributes._id || '-'}
									dataTestId="subscriber-id"
								/>
								<TableRow
									icon={DeezerLogoIcon}
									label="Deezer ID"
									value={data.attributes.userId || '-'}
									isLastRow
									dataTestId="deezer-id"
								/>
							</Tbody>
						</Table>
					</Box>
					<Box width={['100%']} pr={[0]} mb={['spacing.xl']}>
						<Table
							borderWidth="1px"
							borderStyle="solid"
							borderColor="divider.neutral.primary.default"
							data-testid="current-offer-section"
						>
							<Tbody>
								<TableHeading>Current offer</TableHeading>
								<TableRow
									icon={ClockOutlinedIcon}
									label="Status"
									isStatus={true}
									isActivated={data.attributes.linked}
									dataTestId="current-offer-status"
								/>
								<TableRow
									icon={PremiumOutlinedIcon}
									label="Offer code"
									value={offer?.attributes.legacyOfferId || '-'}
									dataTestId="current-offer-code"
								/>
								<TableRow
									icon={PremiumOutlinedIcon}
									label="Offer name"
									value={offer?.attributes.name || '-'}
									dataTestId="current-offer-name"
								/>
								<TableRow
									icon={CalendarIcon}
									label="Start date"
									value={
										new Date(
											data.attributes.offerStartDate,
										).toLocaleDateString() || '-'
									}
									dataTestId="current-offer-start-date"
								/>
								<TableRow
									icon={CalendarIcon}
									label="End date"
									value={
										new Date(
											data.attributes.offerEndDate,
										).toLocaleDateString() || '-'
									}
									dataTestId="current-offer-end-date"
								/>
								<TableRow
									icon={CalendarIcon}
									label="Activation date"
									value={
										new Date(data.attributes.insertDate).toLocaleDateString() ||
										'-'
									}
									isLastRow
									dataTestId="current-offer-activation-date"
								/>
							</Tbody>
						</Table>
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default CustomerDetails;
